import React from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import Img from "gatsby-image"
import {
  Row,
  Col,
  Button,
  Form,
  FormToggle,
  InputGroup,
  FormControl,
  FormCheck,
} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const FaqPageTemplate = ({ title, content }) => {
  return (
    <Layout style={{ backgroundColor: "#b5b8d7ff" }}>
      <SEO title="FAQ" />
      <Row className="mx-0" style={{ marginTop: "-73px" }}>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center faq-section">
            <div style={{ height: "70vh" }}>
              <h1 className="sectionTitle">{title}</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center faq-list">
        <Col xs={10} lg={8}>
          <ReactMarkdown source={content} linkTarget="_blank" />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center align-items-center faq-contact-form">
        <Col xs={10} lg={8}>
          <h1 style={{ textAlign: "center", color: "#344583ff" }}>
            STILL HAVE QUESTIONS?
          </h1>
          <Form className="mt-5">
            <Row>
              <Col xs={6}>
                <Form.Control size="lg" placeholder="first name" />
              </Col>
              <Col xs={6}>
                <Form.Control size="lg" placeholder="last name" />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Control size="lg" placeholder="email address" />
              </Col>
              <Col xs={6}>
                <Form.Control size="lg" as="select" placeholder="email address">
                  <option disabled selected>
                    Questions concerning...
                  </option>
                  <option>How it works</option>
                  <option>Contribute</option>
                  <option>What Lightschools does</option>
                  <option>Academy</option>
                  <option>Memberships</option>
                  <option>Principles</option>
                  <option>Foundations</option>
                  <option>Events</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="message"
                    className="mb-0"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  type="checkbox"
                  className="mb-3"
                  label="Check this box if you would like to stay informed on our events & developments"
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center mb-5 pb-5">
              <Col xs={4} lg={2}>
                <Button className="send">SEND</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Layout>
  )
}

const FaqPage = props => {
  const { frontmatter } = props.data.FaqPageTemplate
  console.log(frontmatter)
  return (
    <FaqPageTemplate title={frontmatter.title} content={frontmatter.content} />
  )
}

export default FaqPage

export const pageQuery = graphql`
  query($id: String!) {
    FaqPageTemplate: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        content
      }
    }
  }
`
